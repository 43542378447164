@tailwind base;
@tailwind components;
@tailwind utilities;

.h-safe-screen {
  /* equivalent to 100dvh in major browsers */
  min-height: calc(100vh - env(safe-area-inset-bottom, 0) - env(safe-area-inset-top, 0));
}

@supports (-webkit-touch-callout: none) {
  .h-safe-screen {
    /* for ios safari 15, safe-area-inset-bottom is 0, so a special fix apply here */
    min-height: -webkit-fill-available;
  }
}
